/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function () {
        $.fn.isInViewport = function () {
          var elementTop = $(this).offset().top;
          var elementBottom = elementTop + $(this).outerHeight();
          var viewportTop = $(window).scrollTop();
          var viewportBottom = viewportTop + $(window).height();
          return (elementBottom - 400) > viewportTop && (elementTop + 400) < viewportBottom;
        };

        $('#equipment-and-toys a.more').on('click', function(e){
          e.preventDefault();
          console.log($('#equipment-and-toys .hidden'));
          $('#equipment-and-toys .hidden-equipment').slideToggle();
          $(this).hide();
        });

        new WOW().init();
        $(".navbar .nav li a,.widget_nav_menu li a,a.scroll-to").click(function (e) {
          e.preventDefault();
          $(".navbar .nav li a,.widget_nav_menu li a.active").removeClass('active');
          $(this).addClass('active');
          var target_id = $(this).attr('href');
          $([document.documentElement, document.body]).animate({
            scrollTop: $(target_id).offset().top
          }, 1000);
        });

        $(document).on('click', '[data-toggle="lightbox"]', function (event) {
          event.preventDefault();
          $(this).ekkoLightbox();
        });

        $(document).on('click', '.nav--open .menu-item a', function (event) {
          event.preventDefault();
          $(".nav--open").removeClass('nav--open');
        });


        $(window).on('resize scroll', function () {
          $("main > section").each(function (index) {
            if ($(this).isInViewport()) {
              var target_id = $(this).attr('id');
              if(!$(target_id).hasClass('active')){
                console.log(target_id);
                $(".navbar .nav li a,.widget_nav_menu li a.active").removeClass('active');
                $(".navbar a[href='#"+ target_id +"']").addClass('active');
                $(target_id).addClass('active');
              }
              return false;
            }
          });
        });

      },
      finalize: function () {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function () {
        // JavaScript to be fired on the home page
      },
      finalize: function () {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function () {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function (func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function () {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.


const nav = document.querySelector('nav');
const menu = document.querySelector('#menu-menu-1');
const menuToggle = document.querySelector('.nav__toggle');
var isMenuOpen = false;

// TOGGLE MENU ACTIVE STATE
menuToggle.addEventListener('click', function (e) {
  e.preventDefault();
  isMenuOpen = !isMenuOpen;
  // toggle a11y attributes and active class
  menuToggle.setAttribute('aria-expanded', String(isMenuOpen));
  menu.hidden = !isMenuOpen;
  nav.classList.toggle('nav--open');
});
